import axios from 'axios';
import { SlugGetters } from '@/slug.store';

const { getSubtenantUrl } = SlugGetters;

const optionsToQueryParams = (page, limit, sorters, filters) => {
  let queryParams = '';

  if (page) queryParams += `page=${page}`;
  if (limit) queryParams += `${queryParams.length === 0 ? '' : '&'}limit=${limit}`;

  Object.keys(sorters).forEach(key => {
    if (sorters[key] === '' || sorters[key] === null || sorters[key] === undefined) return;

    queryParams += `${queryParams.length === 0 ? '' : '&'}sort_${key}=${sorters[key]}`;
  });
  Object.keys(filters).forEach(key => {
    if (filters[key] === '' || filters[key] === null || filters[key] === undefined) return;

    if (key === 'sentAt') {
      if (!filters[key].from || !filters[key].to) return;
      queryParams += `${queryParams.length === 0 ? '' : '&'}filter_${key}From=${filters[key].from}&filter_${key}To=${filters[key].to}`;
    } else {
      queryParams += `${queryParams.length === 0 ? '' : '&'}filter_${key}=${filters[key]}`;
    }
  });

  return queryParams;
};

const findAllForSubtenant = async (page, limit, sorters, filters) => {
  try {
    const response = await axios.get(`${getSubtenantUrl()}/sms?${optionsToQueryParams(page, limit, sorters, filters)}`);
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const findAllForUserAndSubtenant = async (page, limit, sorters, filters) => {
  try {
    const response = await axios.get(`${getSubtenantUrl()}/sms/user?${optionsToQueryParams(page, limit, sorters, filters)}`);
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

export default {
  findAllForSubtenant,
  findAllForUserAndSubtenant,
};
