const serializeSms = data => {
  if (data.responseUpdates.length > 0) {
    data.price = data.responseUpdates[0].price.pricePerMessage;
    data.currency = data.responseUpdates[0].price.currency;
  }

  return {
    _id: data._id,
    messageTo: data.messageTo,
    messageText: data.messageText,
    isSent: data.isSent,
    sentAt: new Date(data.sentAt),
  };
};

export const serializeSmsList = data => {
  return {
    pages: Number(data.pages),
    page: Number(data.page),
    limit: Number(data.limit),
    total: Number(data.total),
    data: (data.data ?? []).map(item => serializeSms(item)),
  };
};
